@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(13, 13, 13, 0.95);
    color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0px 4px 4px #00000040;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1rem 2rem;

  }
  .logo img{
    width: 9rem;
    height: 50px;
  }
  .logo:hover img {
    transform: translateY(-3px);
  }
  .nav {
    display: flex;
    gap: 1rem;
  }
  
  .nav a {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 400;
    padding: 0.5rem 1rem;
    cursor: pointer;
    opacity: 0; 
    animation: slideIn 0.5s forwards;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
 
  .nav a:hover {
    background-color: #302e2e30; 
    color: #fffdfd; 
    text-decoration: none;
    border-radius: 10px;
    transform: translateY(-3px); 
    box-shadow:-1px 1px 16px 0px #7d08d7;
  }
  .nav a:nth-child(1) {
    animation-delay: 0.1s;
  }
  .nav a:nth-child(2) {
    animation-delay: 0.2s;
  }
  .nav a:nth-child(3) {
    animation-delay: 0.3s;
  }
  .nav a:nth-child(4) {
    animation-delay: 0.4s;
  }

  .nav a.active,
.mobile-menu a.active,
.login-register.active {
  background-color: #8dda69;
  color: #19181a;
  border-radius: 10px;
}

  .login-register{
    margin-right: 10px;
    border-radius: 15px;
    opacity: 1;
    background: #000000;

  }
  .hamburger {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 1rem 2rem;
  }
  
  .mobile-menu {
    display: flex;
    flex-direction: column;
    background-color: #141416;
    position: absolute;
    top: 61px; 
    width: 100%;
    left: 0;
    transition: max-height 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
    border-bottom-left-radius:15px ;
    border-bottom-right-radius:15px ;
  }
  
  .mobile-menu a {
    padding: .5rem;
    margin-right: 3rem;
    color: #fff;
    text-decoration: none;
    text-align: right;
    cursor: pointer;
  }
  
  .mobile-menu.expanded {
    max-height: 370px; 
    
  }
  
  @media (max-width: 768px) {
    .nav {
      display: none;
    }
  
    .hamburger {
      display: block;
    }
    .logo img{
      width: 5rem;
      height: auto;
    }
  }
  