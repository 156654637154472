
.business-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: rgba(18, 18, 18, 1);
    position: relative;
    color: rgba(255, 253, 253, 1);
}

.business-image {
    width: 100%;
    height: auto;
    max-height: 401px;
    border-radius: 6px 0px 0px 0px;
    object-fit: cover;
}


.business-content {
    max-width: 800px;
    padding: 20px;
    margin-bottom: 70px;
}

.business-content h2 {
    margin: 20px 0;   
    color: #FFFDFD;

}
.business-content h3{
    margin: 20px 0;
    color: #c7c5c5;

}
.business-content p {
    margin: 10px 0;
    
}
.business_details{
    color: #c7c5c5;
   font-weight: 400;
   font-size: 15px;
}
.phone-icon {
    margin-right: 8px;
    color: rgba(255, 253, 253, 1);
}

.business-address p {
    margin: 5px 0;
    color: #FFFDFD;
    text-decoration: none;
}

.phone_nb {
    color: rgba(255, 253, 253, 1);
    text-decoration: none;
}

.business-address a:hover {
    text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 768px) {
    .business-content {
        padding: 15px;
        margin-bottom: 20px;
    }

    .business-content h2, .business-content h3 {
        margin: 15px 0;
    }
    .business-image{
        height: 170px;

    }
}

@media (max-width: 480px) {
    .business-content {
        padding: 10px;
    }

    .business-content h2, .business-content h3 {
        margin: 10px 0;
        
    }

    .business-content p {
        margin: 6px 20px;
        font-size: 0.7rem;
    }

}
