/* Login.module.css */

.login {
  font-family: Arial, sans-serif;
  background-color: #2a2727;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: .5rem 0 4rem 0;
  min-height: 600px;
}

.login h3 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.appointmentForm {
  display: flex;
  flex-direction: column;
  justify-content: left;
 
}

.formGroup {
  margin-bottom: 10px;
  max-width: 45rem;
}

.login label {
  color: white;
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
  
}
.labelName{
  text-align: left;
}
.inputGroup {
  position: relative;
}



.login input{
  width: 329px;
  height: 18px;
  padding: 6px 17px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  outline: none;
  
  
}
.login select{
  width: 364.6px;
  height: 31.6px;
  padding: 6px 17px;

  border: 1px solid #333;
  border-radius: 5px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  outline: none;
  -webkit-appearance: none;  /* For Chrome, Safari, and Opera */
  -moz-appearance: none;     /* For Firefox */
  appearance: none; 
}

input[readonly],
select {
  background-color: #ffffff;
}

.appointmentType .buttonGroup {
  display: flex;
  width: 75%;
}

.appointmentType .buttonGroup button {
  flex: 1;
  padding: 4px 10px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.appointmentType .buttonGroup button:last-child {
  margin-right: 0;
}

.appointmentType .buttonGroup .online {
  background-color: white;
  color: #000;
}

.appointmentType .buttonGroup .inPerson {
  background-color: #ffffff;
  color: #000000;
}

.appointmentType .buttonGroup .selected {
  background-color: #007bff;
  color: #fff;
}

.paymentButton {
  width: 62%;
  padding: 11px 10px;
  background-color: #000;
  color: #fff;
  border: none;
  margin-top: 5px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.arrowIcon {
  margin-left: 10px;
}

.paymentOptions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.paymentOptions img {
  margin-right: 10px;
}

.paymentOptions span {
  font-size: 12px;
  color: #ccc;
}
#one input{
  border: 0;
}
.inputGroup {
  position: relative;
}

.customFa {
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
  color: #000000;
  cursor: pointer;
  z-index: 700; /* Ensure it's above other elements */
}
.icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
  margin-right: 5px;
  z-index: 1000;
}
.termsContainer{
 display: flex;
 align-items: center;
 gap: 5px;
}
.checkbox{
  width: auto !important;
}
.lblterm{
  margin-bottom: 0px !important;
  font-weight: normal !important;
}
.tnc{
  cursor: pointer;
  color: white;
}
@media (max-width: 768px) {
  .login {
    margin-bottom: 0;
  }
  

.login input{
  width: 280px;
  /*height: 16px;*/
}
.login select{
  width: 315.6px;
  /*height: 29.6px;*/
}
.loader {
 margin-top: -100px;
}
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.customPhoneInput {
  position: relative; /* Ensure z-index works */
}

.customPhoneInputButton {
  padding: 0; /* Remove extra padding from the button */
  background-color: black; /* Ensure button has the desired background */
  color: #666666; /* Ensure button has the desired text color */
}

.customPhoneInput .country-list {
  background-color: #343434;
  color: #666666;
  z-index: 999; /* Ensure dropdown has the desired z-index */
}

.customPhoneInput .flag-dropdown {
  background-color: black;
  color: #666666;
  z-index: 999; /* Ensure dropdown has the desired z-index */
}

.customPhoneInput .selected-flag {
  background-color: black;
  color: #666666;
}

.customPhoneInput .flag-dropdown.open .country-list .search-box {
  display: block;
  background-color: #343434;
  color: #666666;
}

.customPhoneInput .country-list .search {
  background-color: #343434;
  color: #666666;
}


