@import 'aos/dist/aos.css';
* {
  font-family: 'Nunito', sans-serif;
  
}
.footer {
  position: relative;
  color: white;
  padding: 0px;
  background: rgba(18, 18, 18, 1);
  z-index: 9999;
  margin-top: -5rem;
  overflow: hidden;
  border: 15px;
}

.footer-content {
  position: relative;
  z-index: 1;
  border: 15px;
  background-image: url(/public/map.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 30rem;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center; 
  font-size: 10;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

}
.footer-bg{
  background-color: rgba(255, 255, 255, 0.749);
  height: 200px;
  width: 100%;
  padding: 100px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.address-link{
  padding-top: 30px;
}
.address-link a {
  color: rgb(205, 154, 26); 
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  font-size: 23px;
}

.address-link a:hover {
  text-decoration: underline; 
}

.address p{
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  margin: 0px;
}
.address-header{
  font-weight: 700;
  font-size: 30px;
  color:  rgba(0, 0, 0, 1);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.extraSection{
  padding: 0px 0px 20px 10px;
}
.extraSection p,.extraSection h3{
  text-align: left;
  margin: 0 0 0 .5rem;
  padding: 3px;
  font-size: 14px;
  color: rgba(176, 176, 176, 0.832);
  text-decoration: none;
}
.extraSection p{
  font-weight: 400;
}
.extraSection h3{
  font-weight: 700;
}
.extraSection a{
  text-decoration: none;
  color: rgba(176, 176, 176, 0.832);
}

.payment_method{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
text-align: center;
font-family: Nunito;
font-size: 10px !important;
font-style: normal;
font-weight: 200;
line-height: normal;
text-transform: capitalize;
}
  @media (max-width: 768px) {
    .footer{
      margin-top: 0px;
    }
   
    .address p{
      font-size: 15px;
    }
    .address-header{
      font-size: 19px;
      margin: 40px 0 15px 0;
    }
    .address-link{
      padding-top: 5px;
    }
    .address-link a {
      font-size: 19px;
    }
    .footer-bg{
      padding: 0px;
    }
  
    .extraSection p,.extraSection h3{
      text-align: left;
      margin: 0px;
      padding: 3px;
      font-size: 8px;
      color: rgba(176, 176, 176, 0.832);
      text-decoration: none;
    }
  .payment_method{
    flex-direction: column !important;
    font-size: 7px !important;
  }
}
