.App {
  text-align: center;
  flex: 1; /* Allows the main content to grow and fill available space */
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {

  box-sizing: border-box;
  background: radial-gradient(circle at top right, 
        #8f5bb3 0%, 
        #7f5eb4 40%, 
        #934fa0 90%);

  background-repeat: no-repeat;
  
  padding-top: 60px; 
  font-family: Arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;

}

button {
  cursor: pointer;
}
@media(max-width: 350px){
  body{
    zoom:30%
  }
}
@media(max-width: 295px){
  body{
    zoom:30%
  }
}

