.privacy-policy {
  max-width: 800px;
  margin: 0 auto 106px auto;
  padding: 40px;
  background-color: #fffdfd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
  text-align: left
  }
  
  .privacy-policy h1, .privacy-policy h2 {
    color: #333;
  }
  
  .privacy-policy h1 {
    margin-bottom: 20px;
    font-size: 2em;
    text-align: center;
  }
  
  .privacy-policy h2 {
    margin-top: 20px;
    font-size: 1.5em;
  }
  
  .privacy-policy p {
    margin: 10px 0;
    line-height: 1.6;
  }
  
  .privacy-policy ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  .privacy-policy li {
    margin: 5px 0;
  }
  
  .privacy-policy address {
    font-style: normal;
    line-height: 1.6;
  }
  @media (max-width: 768px) {
    .privacy-policy{
      margin: 0 auto 7px auto;
    }
  }

