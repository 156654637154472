.phoneAuth {
  font-family: Arial, sans-serif;
  background-color: #363333;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: 0 0 4rem 0;
}

.logo {
  width: 5rem;
}

h4 {
  text-align: center;
  margin: 5px 0;
  color: #000;
}

.authForm {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.formGroup {
  margin-bottom: 20px;
}

.phoneAuth label {
  margin-bottom: 10px;
  display: block;
  font-weight: 700;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 15px;
}

.inputGroup {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  
}
.country-list{
  background-color: #282e31 !important;
}

.inputName {
  width: calc(100% - 40px);
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  outline: none;
}
.inputGroup input{
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #000000
}
.icon {
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  color: #999;
  
}

.getOtpButton {
  width: fit-content;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
  margin-bottom: 8px;
}

.otpGroup {
  text-align: center;
}

.otpInputs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.otpInputs input {
  width: 30px;
  height: 27px;
  text-align: center;
  font-size: 18px;
  border: 2px solid #000000;
  border-radius: 10px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.continueButton {
  width: 45%;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.arrowIcon {
  margin-left: 10px;
}
.inner_div{
  min-width: 417px;
  background: #D9D9D9;
  border-radius: 20px;
}
@media(max-width:768px){
  .phoneAuth{
    margin-bottom: 0;
  }
  .inner_div{
    zoom:85%;
  }

}

@media(max-width:382px){
  .inner_div{
    zoom:77%;
  }
}
.editablePhoneInput {
  outline: none;
  border: none;
  box-shadow: none; /* Ensures no shadow appears on focus */
}

.editablePhoneInput:focus {
  outline: none;
  border: none;
  box-shadow: none; /* Ensures no focus border */
}
