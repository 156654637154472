
@import 'aos/dist/aos.css';
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes zoomIn {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}


.main-section a, .hotline {
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.buttons a:hover, .hotline:hover {
  background-color: #5f12694b; 
  color: #fbfafb; 
  text-decoration: none;
}



.profile img {
  max-width: 360px;
  position: relative;
  bottom: 0px;
  padding-left: 50px;

}

/* Rest of your existing CSS */
* {
  font-family: 'Nunito', sans-serif;
}

body {
  max-width: 100%;
  font-family: 'Nunito', sans-serif;
  overflow-y: unset;
}

.main-section {
  margin-top: 50px;
  text-align: center;
  position: relative;
}

.main-section h1 {
  font-size: 3em;
  color: #4b0082;
}

.subheading {
  font-size: 30px;
  color: #faeeb5;
  font-weight: 800;
  margin: 0;
  margin-bottom: 2rem;
}
.phone_nb {
  color: rgba(255, 253, 253, 1);
  text-decoration: none;
}
.buttons {
  margin: 0px 0;
  display: flex;
  flex-direction: column;
}

.buttons a, .hotline {
  width: 356px;
  margin: 0px;
  border: 2px solid #FFBA08;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
  background-color: #695acd00;
  color: white;
  padding: 5px 20px;
  cursor: pointer;
  display: flex; 
  align-items: center; 
  font-size: 21px;
  font-weight: 400;
  text-decoration: none;
  animation: slideIn 0.7s forwards;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.buttons a:nth-child(1) {
  animation-delay: 0.1s;
}



.appointment-button img, .hotline img {
  margin-right: 60px;
}

.counselling {
  display: flex;
  justify-content: space-around;
  padding: 10px 40px 0 40px;
  position: relative;
  top: -9rem;
  margin-bottom: -104px;
}

.individual-counselling,
.couple-counselling {
  width: 43%;
  background: rgba(243, 238, 249, 1);
  padding: 15px 20px 5px 20px;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.individual-counselling:hover, .couple-counselling:hover{
  transition: transform 0.3s ease-in-out 0.2s, box-shadow 0.3s ease-in-out 0.2s; /* ease-in-out added */
  transform: translateY(-5px); 
  box-shadow: -1px 5px 14px rgb(27, 2, 35);

}
.double-love-icon {
  height: 68px;
}

.single-love.icon {
  height: 65px;
}

.counselling h2 {
  color: rgba(216, 156, 0, 1);
  text-align: left;
  font-weight: 700;
  font-size: 30px;
  margin: 0;
}

.counselling p {
  text-align: left;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 17px;
  font-weight: 400;
}

.counselling_bot {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.counselling_bot p {
  font-weight: 900;
}

.profile {
  display: flex;
  align-items: end;
  justify-content: space-between;
  bottom: 0px;
  position: relative;
}

.profile-info {
  text-align: right;
  width: 900px;
  margin-right: 50px;
  padding-bottom: 50px;
}

.profile-info h2 {
  color: rgba(255, 255, 255, 1); 
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 50px;
  font-weight: 700;
}

.profile-info p {
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 400;
  font-size: 22px;
  height: auto;
}

.couple img {
  max-width: 508px; 
  height: auto; 
  animation: zoomIn 0.5s forwards;
  transition: transform 0.5s ease-in-out;
}

.top {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 0px 80px;
  gap: 10%;
}

.web-title {
  margin: 0px;
  font-size: 60px;
  font-weight: 800;
  text-align: left;
  color: #FFFFFF;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
}

.wave {
  display: flex;
  width: 100%;
  height: 70%;
  
  z-index: 999;
  margin-top: -200px;
  margin-bottom: 50px;

}

.wave img {
  width: -webkit-fill-available;
  position: relative;

}



@media (max-width: 1024px) {
  .main-section {
    zoom: 70%;
  }
}

@media (max-width: 768px ) {
  .main-section {
    zoom: 50%;
  }
  .wave img {
    width: -webkit-fill-available;
    top: 50px;
  }
  .top {
    margin-top: 30px;
    justify-content: space-around;
  }

  .text {
    position: absolute;
    left: 2rem;
    top: 1rem;
  }

  .couple{  
    position: relative;
    right: -151px;
    top: 140px;
    margin: 0 0 110px 0;
    scale: 0.7;
  }

  .counselling {
    text-align: -webkit-center;
    justify-content: center;
    margin-bottom: auto;
    display: block;
    position: relative;
    top: -100px;
  }

  .individual-counselling,
  .couple-counselling {
    scale: 1.4;
    width: 67%;
    font-size: 10px;
  }

  .individual-counselling {
    margin-bottom: 156px;
  }

  .individual-counselling h2,
  .couple-counselling h2 {
    margin-bottom: 0;
  }

  .profile-info h2 {
    font-size: 38px;
  }

  .profile-info p {
    padding-left: 50px;
    font-size: 18px;
    font-weight: 400;
  }

  .profile-info {
    margin-top: 50px;
    margin-right: 35px;
    padding-bottom: 60px;
    width: 56%;
  }

  .profile img {
    max-width: 300px;
    padding-left: 20px;
  }
}

@media (max-width: 375px) {
  .main-section {
    zoom: 45%;
  }
}

@media (max-width: 316px) {
  .main-section {
    zoom: 45%;
  }
}



